@keyframes marquee {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-100%);
  }
}
.footer {
  color: white;
  width: 100%;
  background-color: #333;
}
.footer-socials-trademark {
  display: flex;
  justify-content: space-around;
}
.footer-trademark {
} 
.footer-text {
  text-align: left;
  font-family: "Roboto Condensed";
  padding: 0 2rem 1.5rem;
  font-size: 0.8rem;
}
.moving-text-animation {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 1.5rem 0;
}
.moving-text-animation span {
  font-family: "New Spirit Regular";
  font-size: 3.5rem;
  display: inline-block;
  animation: marquee 8s linear infinite;
  position: relative;
  z-index: 1;
}
.footer-socials {
  display: flex;
  padding: 0 0 1.8rem 0;
  width: 50%;
}
.social {
  padding: .8rem .35rem .8rem .35rem;
  margin: auto;
  cursor: pointer;
  transition: 400ms ease;
  border-radius: 50%;
}
.social svg {
}
a.svg {
  position: relative;
  scale: 0.6;
  display: inline-block;
  cursor: pointer;
}
a.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.whatsapp {
  background-color: #18b111;
}
.whatsapp:hover {
  scale: 0.9;
}
.instagram {
  background: #d6249f;
  filter: drop-shadow(2px 0px 0px #d6249f) drop-shadow(-2px -2px 0px #fdf497);
}
.instagram:hover {
  scale: 0.9;
}
.tiktok {
  background-color: #fd3e3e;
  filter: drop-shadow(2px 0px 0px #fd3e3e) drop-shadow(-2px -2px 0px #4de8f4);
}
.tiktok:hover {
  scale: 0.9;
}
@media only screen and (max-width: 600px) {
  .footer-socials-trademark {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer-text {
    text-align: center;
  }

}

