.location {
    width: 100%;
    background-color: white;
}

.map {
    width: 100%;
    padding: 1rem;
}

.location-title {
    text-align: center;
    font-size: 1.2rem;
    font-family: "New Spirit Regular";
    font-weight: bold;
    color: #646464;
    text-transform: uppercase;
}