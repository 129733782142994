.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.main-bar {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  text-transform: uppercase;
}

.logo .valentinna {
  text-transform: none;
  font-family: "Great Vibes";
  font-size: 2rem;
  font-weight: normal;
  padding: 0.3rem 0rem 0rem 1.2rem;
  margin: 0;
}

.logo .salon {
  font-family: "Lato Light";
  font-size: 0.6rem;
  position: absolute;
  top: 2.1rem;
  left: 2.7rem;
  padding: 0rem 0rem 0rem 0rem;
  margin: 0;
  color: #d3a200;
}

.actions {
  font-family: "Lato Light";
  display: flex;
}

.menu {
  font-family: "Lato Light";
  text-transform: uppercase;
  background-color: #ededed;
  display: flex;
  transition: all 0.5s ease;
  text-align: center;
  justify-content: space-around;
}

.down-icon {
  position: fixed;
}

@media only screen and (min-width: 900px) {
  .menu {
    background-color: #fefefe00;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 47%;
    transform: translateX(-50%);
    text-align: center;
    width: 55%;
  }
}

@media only screen and (max-width: 400px) {
  .logo .valentinna {
    font-size: 1.2rem;
    padding: .78rem 0rem 0rem 1rem;
  }

  .logo .salon {
    font-size: 0.37rem;
    top: 1.85rem;
    left: 1.9rem;
  }
}
