@font-face {
  font-family: 'Great Vibes';
  src: url('./assets/fonts/GreatVibes-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: url('./assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/fonts/RobotoCondensed-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoFlex';
  src: url('./assets/fonts/RobotoFlex-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'New Spirit Regular';
  src: url('./assets/fonts/New\ Spirit\ Bold.ttf') format('truetype');
}

body {
  background-color: #d6d5d5;
  transition: all 250ms ease-in-out;
}

.animate-home-component {
  animation-duration: 3s;
  animation-name: animate-fade;
  animation-delay: .5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}