.about-us {
  height: 400px;
  width: 100%;
  background-color: white;
}

.about-us-title {
  padding: 1rem 2rem 0rem 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-family: "New Spirit Regular";
  font-weight: bold;
  text-transform: uppercase;
  color: #646464;
  opacity: 1;
  transition: opacity .8s ease;
}

.about-us-title.fade-out {
  opacity: 0;
}

