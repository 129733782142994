.contacto-button {
  color: #252525;
  padding: 1.1875rem 1.5625rem 1.1875rem 1.1rem;
  margin: 0;
  font-size: 0.81rem;
  text-decoration: none;
  transition: all 0.5s ease;
}

.contacto-button:hover {
  filter: brightness(200%); 
}

.contacto-button:visited {
  color: #252525;
}

.contacto-button:visited:hover {
  color: #616161;
}

.reservas-button {
  padding: 1.1875rem 1.5625rem;
  font-size: 0.81rem;
  text-decoration: none;
  color: #ffffff;
  background-color: #d3a200;
  text-decoration: none;
  transition: all 0.5s ease;
}

.reservas-button:hover {
  color: #252525;
  background-color: #e6c55c;
}

.reservas-button:visited {
  color: #ffffff;
  background-color: #d3a200;
}

.reservas-button:visited:hover {
  color: #252525;
  background-color: #e6c55c;
}


@media only screen and (max-width: 400px) {  
  .contacto-button {
    font-size: 0.61rem;
  }

  .reservas-button {
    font-size: 0.61rem;
  }
}
