.main-image {
  padding: 5.8rem 0 0 0;
  color: white;
  text-align: center;
}

.main-image h1 {
  margin: 3.5rem 0 1.5rem 0;
  line-height: 0.8;
  font-size: 3.3rem;
  font-family: "Great Vibes";
}

.main-image h2 {
  margin: 0 0 1.5rem 0;
  line-height: 0.8;
  font-size: 3.3rem;
  font-family: "Great Vibes";
}

.main-image h3 {
  font-size: 1rem;
  font-family: "Roboto Condensed";
}

.main-image-button {
  align-items: center;
  background-clip: padding-box;
  background-color: #d3a200;
  border: 1px solid transparent;
  border-radius: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Roboto Condensed";
  font-size: 1.4rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  margin-top: 2rem;
  margin-bottom: 2.3rem;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.main-image-button:hover,
.main-image-button:focus {
  scale: 1.1;
  background-color: #e6c55c;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: black;
  /* padding: calc(.975rem - 1px) calc(1.8rem - 1px); */
}

.main-image-button:hover {
  transform: translateY(-1px);
}

.main-image-button:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

@media only screen and (min-width: 900px) {
  .main-image h1 {
    margin: 0 0 1.5rem 0;
    font-size: 4.6rem;
  }

  .main-image h2 {
    font-size: 4.6rem;
  }

  .main-image h3 {
    font-size: 1.35rem;
  }

  .main-image-button {
    font-size: 1.9rem;
  }
}

@media only screen and (max-width: 600px) {
  .main-image h1 {
    font-size: 2.6rem;
  }
  .main-image h2 {
    font-size: 2.6rem;
  }
  .main-image h3 {
    font-size: .7rem;
  }
  .main-image-button {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .main-image h1 {
    font-size: 1.9rem;
  }
  .main-image h2 {
    font-size: 1.9rem;
  }
  .main-image h3 {
    font-size: .7rem;
  }
  .main-image-button {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 300px) {
  .main-image h1 {
    font-size: 1.1rem;
  }
  .main-image h2 {
    font-size: 1.1rem;
  }
  .main-image h3 {
    font-size: .7rem;
  }
  .main-image-button {
    font-size: .8rem;
  }
}

@media only screen and (max-width: 250px) {
  .main-image h1 {
    font-size: 1rem;
  }
  .main-image h2 {
    font-size: 1rem;
  }
  .main-image h3 {
    font-size: .6rem;
  }
  .main-image-button {
    font-size: .7rem;
  }
}
