.home-images-container {
  padding-top: 2rem;
}

.link-images-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.link-image {
  position: relative; 
  margin: 0; 
  width: 50%;
}

.link-image:hover {
  filter: brightness(130%);
}

.link-images-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  transition: color 0.3s;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: "Lato Light";
}

.link-image:hover .link-images-text {
  color: #e6c55c;
}

@media only screen and (min-width: 1100px) {
  .link-images-text {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 800px) {
  .link-images-container {
    flex-wrap: wrap;
  }
  .link-images-text {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .link-images-text {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .link-images-text {
    font-size: .9rem;
  }
}
