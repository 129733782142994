.menu-button {
  padding: 1.1883rem 1.1rem;
  margin: 0;
  font-size: 0.81rem;
  text-decoration: none;
  color: #252525;
  transition: all 0.5s ease;
}

.menu-button:hover {
  background-color: #ededed;
}

.menu-button:visited {
  color: #252525;
}

.menu-button:visited:hover {
  background-color: #ededed;
}

.dropdown-menu {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.menu-button.menu-button-with-icon:hover .dropdown-menu,
.menu-button.menu-button-with-icon:focus .dropdown-menu {
  opacity: 1;
  max-height: 500px; /* Set a high value to ensure all content is visible */
}

.dropdown-menu ul {
  list-style: none;
}

.dropdown-menu li {
  margin: 2rem 0 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .menu-button {
    padding: 1.1883rem 0;
  }
}

@media only screen and (max-width: 400px) {
  .menu-button {
    padding: 1.1883rem 0;
    font-size: 0.61rem;
  }
}
